import { render, staticRenderFns } from "./DetailVacante.vue?vue&type=template&id=fc60b6ae&scoped=true&"
import script from "./DetailVacante.vue?vue&type=script&lang=js&"
export * from "./DetailVacante.vue?vue&type=script&lang=js&"
import style0 from "./DetailVacante.vue?vue&type=style&index=0&id=fc60b6ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc60b6ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCol,VFileInput,VForm,VListItem,VListItemContent,VListItemTitle,VRow,VTextField,VTextarea})
