var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"grey py-14"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"1","lg":"1","xl":"1"}}),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3","lg":"3","xl":"3"}},[_c('div',{staticClass:"text-h4 font-weight-bold d-flex justify-center justify-md-start"},[_vm._v(" Filtros ")]),_c('v-list-item',{staticClass:"pa-6 pa-md-0"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 py-1 font-weight-bold"},[_vm._v(" Departamentos ")]),_c('v-list-item',{staticClass:"pa-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mx-2 my-0"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_vm._v("Ventas")])],1)]}}])}),_c('v-list-item',{staticClass:"pa-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mx-2 my-0"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_vm._v("Trafico")])],1)]}}])}),_c('v-list-item',{staticClass:"pa-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mx-2 my-0"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_vm._v("Ventas")])],1)]}}])}),_c('v-list-item',{staticClass:"pa-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mx-2 my-0"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_vm._v("Trafico")])],1)]}}])}),_c('v-list-item',{staticClass:"pa-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mx-2 my-0"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_vm._v("Ventas")])],1)]}}])}),_c('v-list-item',{staticClass:"pa-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mx-2 my-0"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_vm._v("Trafico")])],1)]}}])})],1)],1),_c('v-list-item',{staticClass:"pa-6 pa-md-0"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 py-1 font-weight-bold"},[_vm._v(" Ubicacion ")]),_c('v-list-item',{staticClass:"pa-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mx-2 my-0"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_vm._v("Chihuahua")])],1)]}}])}),_c('v-list-item',{staticClass:"pa-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mx-2 my-0"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_vm._v("Ciudad Juarez")])],1)]}}])}),_c('v-list-item',{staticClass:"pa-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mx-2 my-0"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_vm._v("El paso")])],1)]}}])})],1)],1),_c('div',{staticClass:"mt-4 d-flex justify-center justify-md-start"},[_c('v-btn',{attrs:{"small":"","depressed":"","outlined":"","color":"secondaryGreen"}},[_vm._v(" Limpiar Filtros ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"9","md":"7","lg":"7","xl":"7"}},[_c('div',{staticClass:"text-h4 font-weight-bold d-flex justify-center justify-md-start"},[_vm._v(" Todas las vacantes ")]),(_vm.loading)?_c('div',{staticClass:"d-flex justify-center justify-md-start ml-0 ml-md-8 my-8"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primaryGreen"}})],1):_c('div',_vm._l((_vm.list),function(element){return _c('v-list-item',{key:element.id,staticClass:"pa-6 pa-md-0"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 py-1"},[_c('v-btn',{staticClass:"text-h6 text-none text-decoration-underline secondaryGreen--text",attrs:{"text":""},on:{"click":function($event){return _vm.handleDetail(element)}}},[_vm._v(" "+_vm._s(element.name)+" ")])],1),_c('v-list-item-title',{staticClass:"d-flex flex-column flex-md-row"},[_c('div',{staticClass:"mr-8"},[_vm._v(_vm._s(element.location))]),_c('div',{staticClass:"mr-8"},[_vm._v(" Fecha de publicación: "+_vm._s(element.created_at.substring(0, 10))+" ")]),_c('div',{staticClass:"mr-8"},[_vm._v(" Vigencia: "+_vm._s(element.expired_at.substring(0, 10))+" ")])])],1)],1)}),1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"1","lg":"1","xl":"1"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }